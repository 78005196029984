.App {
  text-align: center;
}
@font-face {
  font-family: NunitoBl;
  src: url('./fonts/Nunito-Black.ttf');
}
@font-face {
  font-family: NunitoR;
  src: url('./fonts/Nunito-Regular.ttf');
}
@font-face {
  font-family: NunitoB;
  src: url('./fonts/Nunito-Bold.ttf');
}
@font-face {
  font-family: NunitoL;
  src: url('./fonts/Nunito-Light.ttf');
}


body
{
  background-image: url('./images/23\ –\ 5.png');
  
    background-size: cover;
    background-repeat: no-repeat;
}

.name img{
  width: 400px;
}
.featurebox
{
  display: flex;
    justify-content: space-around;
    flex-direction: row;
    padding: 40px;
    flex-wrap: wrap;
}
.FeatureHead h3{
  color: #ffff;
    margin: 0;
    margin-bottom: 10px;
    font-size: 20px;
    font-family: 'NunitobL';
    text-align: left;
    padding-left: 20px;
}
.feature_Section 
{
  padding: 50px;
}
.Fimage img{
  width: 300px;
}
.feature_Section .title h2
{
  text-align: left;
  font-family: 'NunitoBl';
  color: #fff;
  font-size: 30px;
}
.center {
  padding: 0px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 250px;
}
.center h3{
  font-size: 30px;
  font-family: 'NunitoBl';
  color: #fff;
}
.Signup h6
{
  padding: 0px;
  font-size: 25px;
  font-family: 'Nunitol';
  color: #fff;
}
.instruction 
{
  padding: 50px;
}
#alert 
{
  height: 40px;
}
.instruction h2
{
  text-align: left;
  font-size: 40px;
  color: #fff;
  font-family: 'NunitoBl';
}
.instruction p{
  font-family: 'Nunitol';
  text-align: left;
  color: #e6e6e6;
  padding-right: 100px;
}
.Signup
{
  margin: 20px;
}
.Signup h3{
  font-size: 30px;
  font-family: 'NunitoBl';
  color: #fff;

}
.Signup form button:hover{
  background-color: #000;
}

.Signup form button
{
  margin-left: 5px;
    padding: 8px 20px;
    font-size: 20px;
    border: none;
    background-color: #FF9B50;
    color: #fff;
    font-family: 'NunitoR';
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;

}
.Signup form input[type='email']
{
  font-size: 20px;
  font-family: 'NunitoR';
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  border: none;

  padding: 8px 18px;
}
.Signup form input[type='email']:focus,.Signup form input[type='email']:active{
outline: none;
}
.footer .description p{
  font-family: 'NunitoR';
    color: #fff;
}
.Signup alert
{
  border-radius: 10px;
  font-family: NunitoB;
  color: #1f1f1f;
  display: inline-block;
  background: #e6e6e6;
  padding: 6px;
  margin: 10px;
  transition: 0.6s ease;

}

.footer .Logo img{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width :150px;
/*border-radius: 100px;*/

}
.footer .description h3
{
  font-family: 'NunitoBl';
  color: #fff;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (min-width:320px)  { 
  .instruction p {
    padding-right: 50px;
  }
  .Signup form input[type='email']
  {
    width: 200px;
  }/* smartphones, iPhone, portrait 480x320 phones */ }
@media (min-width:481px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
  .Signup form input[type='email']
  {
    width: 200px;
  }
  .instruction p {
    padding-right: 50px;
  }
}
@media (min-width:641px)  { /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ 

  .Signup form input[type='email']
  {
    width: auto;
  }
  .instruction p {
    padding-right: 50px;
  }}
@media (min-width:961px)  { 
  .Signup form input[type='email']
  {
    width: auto;
  }/* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media (min-width:1025px) {
  .Signup form input[type='email']
  {
    width: auto;
  } 
  .instruction p {
    padding-right: 100px;
  }/* big landscape tablets, laptops, and desktops */ }
@media (min-width:1281px) {
  .Signup form input[type='email']
  {
    width: auto;
  }
  .instruction p {
    padding-right: 100px;
  } /* hi-res laptops and desktops */ }
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}




@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
